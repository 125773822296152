<template>
  <div class="deviceManagerList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select label="通用类别" v-model="searchParams.type" :options="typeOpts" />
        <v-input label="内容" v-model="searchParams.content" />
        <v-datepicker label="时间" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate" />
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionIdSel2Params" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getCommonListURL, exportCommonListURL } from './api'
import { regionParams } from 'common/select2Params'
import { typeOpts } from './map'

export default {
  name: 'DeviceManagerList',
  data () {
    return {
      searchUrl: getCommonListURL,
      exportUrl: exportCommonListURL,
      typeOpts,
      searchParams: {
        type: undefined,
        content: '',
        regionId: undefined,
        startDate: '',
        endDate: ''
      },
      regionIdSel2Params: regionParams,
      headers: [
        {
          prop: 'typeName',
          label: '类别'
        },
        {
          prop: 'content',
          label: '内容'
        },
        {
          prop: 'count',
          label: '关联设备',
          align: 'right'
        },
        {
          prop: 'userName',
          label: '创建人'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'deviceConfigCommonForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'deviceConfigCommonEdit',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
