<template>
  <div class="deviceConfigList-wrapper">
    <list ref="list"
          exportPermission
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :extraParams="extraParams"
          :headers="headers"
          :isMultiSelect="true"
          :page-size="200"
          :isOld="true"
          :multiExport="multiExport"
          @searchSuccess="searchSuccess">
      <template #headerSlot>
        <!--<v-button text="批量生成二维码"-->
        <!--          :disabled="tableData.resultList.length==0||changeFlag"-->
        <!--          permission="generateQrcode"-->
        <!--          @click="batchCreateQrcode"></v-button>-->
        <!-- <v-button text="批量关联二维码"
                  permission="relevanceQrcode"
                  @click="goPage('batchRelevanceQrcode')"></v-button> -->
        <v-button text="批量修改"
                  permission="update"
                  @click="goPage('batchEdit')"></v-button>
        <v-button text="生成物管台账"
                  v-if="userType === '101'"
                  permission="add"
                  @click="createDb"></v-button>
        <v-button text="登记设备"
                  permission="add"
                  @click="goPage('deviceConfigForm')"></v-button>
        <v-button text="导入台账"
                  permission="importDevice"
                  @click="goPage('deviceConfigImport')"></v-button>
      </template>
      <template #tableLeftSlot>
        <div class="tree-group">
          <div class="tree-group-header">
            <div>设备分类</div>
            <el-button @click="cancleTree" size="small" type="primary">取消</el-button>
          </div>
          <div class="tree-group-content">
            <v-tree
              ref="tree"
              v-loading="treeLoading"
              :data="treeData"
              :defaultProps="defaultProps"
              :expand-on-click-node="false"
              :highlightCurrent="highlightCurrent"
              @nodeClick="nodeClick"
            />
          </div>
        </div>
      </template>
      <template #searchSlot>
        <v-datepicker label="登记时间段"
                      :startTime.sync="searchParams.startDate"
                      :endTime.sync="searchParams.endDate"
                      ></v-datepicker>
        <v-input label="设备名称"
                 v-model="searchParams.name"></v-input>
        <v-input label="设备编号"
                 v-model="searchParams.serialNum"></v-input>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"
                   @onChange="communityChange"></v-select2>
        <v-select label="状态"
                  v-model="searchParams.deviceStatus"
                  :options="deviceStatusOps"></v-select>
        <!-- <v-select2 label="三级分类"
                   v-model="searchParams.thirdLevel"
                   v-bind="thirdLevelParams"></v-select2>
        <v-select2 label="一级分类"
                   v-model="searchParams.firstLevel"
                   v-bind="firstLevelParams"></v-select2>
        <v-select2 label="二级分类"
                   v-model="searchParams.secondLevel"
                   v-bind="secondLevelParams"></v-select2> -->
        <v-input label="安装位置"
                 v-model="searchParams.address"></v-input>
        <v-select label="组团"
                  v-model="searchParams.area"
                  :options="areaOps"></v-select>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="tenantParams"></v-select2>
        <v-select label="是否巡检"
                  v-model="searchParams.isPatrol"
                  :options="patrolStatusOps"></v-select>
        <v-select label="是否维保"
                  v-model="searchParams.isMaint"
                  :options="maintenanceStatusOps"></v-select>
        <v-select label="巡检/维保方式"
                  v-model="searchParams.workType"
                  :options="workTypeSearchOps"></v-select>
        <v-input label="登记人"
                 v-model="searchParams.userName"></v-input>
        <v-select2 label="设备房"
                   v-model="searchParams.deviceRoomId"
                   v-bind="deviceRoomParams"></v-select2>
        <v-select2 label="维保单位"
                   v-model="searchParams.maintCompanyId"
                   v-bind="maintCompanyParams"></v-select2>
        <v-select2 label="通用内容"
                   v-model="searchParams.commonContent"
                   v-bind="commonContentParams"></v-select2>
        <v-input label="品牌"
                 v-model="searchParams.brand"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="edit(scope.row)"></v-button>
        <el-dropdown trigger="click"
                     @command="moreHandle">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }">
              <span>生成二维码</span>
            </el-dropdown-item>
            <!-- isPatrol为0是巡检为否,选择否当前按钮不出现 -->
            <el-dropdown-item v-if="scope.row.isPatrol !== 0" :command="{ row: scope.row, type: 2 }">
              <span>生成巡检日志</span>
            </el-dropdown-item>
            <!-- isMaint为0是维保为否,选择否当前按钮不出现 -->
            <el-dropdown-item v-if="scope.row.isMaint !==0 " :command="{ row: scope.row, type: 3 }">
              <span>生成维保计划</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量生成二维码"
                  :disabled="scope.selectedData.ids.length === 0"
                  permission="generateQrcode"
                  @click="batchCreateQrcode(scope.selectedData)"></v-button>
        <v-button text="批量生成巡检日志"
                  :disabled="scope.selectedData.ids.length === 0"
                  permission="InspectionLog"
                  @click="batchCreatePatrolRecord(scope.selectedData)"></v-button>
        <v-button text="批量生成维保计划"
                  :disabled="scope.selectedData.ids.length === 0"
                  permission="maintenancePlan"
                  @click="batchCreateMaintRecord(scope.selectedData)"></v-button>
        <v-button text="批量删除"
                  :disabled="scope.selectedData.ids.length === 0"
                  permission="BatchDele"
                  @click="batchDelete(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog title="下载二维码"
               width="500px"
               :visible.sync="dialogQrcode"
               @closed="closeDialog">
      <div class="qrcode-wrapper">
        <div>
          <h3 class="title-text">
            {{ qrCodeObj.name }}
          </h3>
        </div>
        <div class="qrcode-value">
          <span> 二维码值：{{ qrCodeObj.code }} </span>
        </div>
        <div class="qrcode-img-wrapper">
          <div v-if="qrCodeId"
               :id="qrCodeId"
               class="img"></div>
        </div>
        <div class="qrcode-value">
          <span> 位置：{{ qrCodeObj.address }} </span>
        </div>
        <v-button @click="downloadQRCode"
                  text="保存到本地"
                  type="success"></v-button>
      </div>
    </el-dialog>
    <qrcode-collection :visible.sync="dialogQrcodeList"
                       :qrcode-list="qrcodeList">
      <template #qrcodeSlot="scope">
        <div style="display: flex;flex-direction: column;">
          <span>{{scope.row.serialNum}}</span>
          <span>{{scope.row.name}}</span>
          <span>{{scope.row.address}}</span>
        </div>
      </template>
    </qrcode-collection>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getCategoryListURL,
  getAllDeviceRoomURL,
  getMaintenanceUnitURL,
  getContentCommonURL,
  getGenerateQrcodeURL,
  batchGenerateQrcodeURLNew,
  generateDevicePatrolSingleURL,
  exportDeviceToExcelURL,
  exportQrcodeExcelURL,
  batchDeleteDeviceURL,
  // getBlockNameFromCommunityURL,
  generateOneCommunityDeviceDBURL,
  getTreeDataURL,
  generateMaintLogURL,
  generateDevicePatrolBatchURL
} from './api'
import {
  deviceStatusOps,
  workTypeSearchMap,
  workTypeSearchOps,
  patrolMaintenanceSearchStatusMap,
  patrolMaintenanceSearchStatusOps,
  periodOpsMap,
  periodFormMap
} from './map'
import { communityParams, tenantParams } from 'common/select2Params'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { downloadHelper } from 'common/utils'
import QRCode from 'qrcodejs2'
import { QrcodeCollection } from 'components/bussiness'
import { vTree } from 'components/control/index'
import { select2BlockURL } from 'common/api'
import moment from 'moment'
export default {
  name: 'DeviceConfigList',
  components: {
    elDropdown: Dropdown,
    elDropdownMenu: DropdownMenu,
    elDropdownItem: DropdownItem,
    QrcodeCollection,
    vTree
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      deviceStatusOps,
      communityParams,
      tenantParams,
      workTypeSearchOps: workTypeSearchOps(1),
      patrolStatusOps: patrolMaintenanceSearchStatusOps(1),
      maintenanceStatusOps: patrolMaintenanceSearchStatusOps(1),
      multiExport: [{
        text: '导出设备列表',
        url: exportDeviceToExcelURL,
        permission: 'exportDevice'
      }, {
        text: '导出二维码',
        method: 'new',
        url: exportQrcodeExcelURL,
        permission: 'qrcodeExport'
      }],
      thirdLevelParams: {
        searchUrl: `${getCategoryListURL}?codeLevel=3`
      },
      firstLevelParams: {
        searchUrl: `${getCategoryListURL}?codeLevel=1`
      },
      secondLevelParams: {
        searchUrl: `${getCategoryListURL}?codeLevel=2`
      },
      deviceRoomParams: {
        searchUrl: getAllDeviceRoomURL
      },
      maintCompanyParams: {
        searchUrl: getMaintenanceUnitURL,
        request: {
          text: 'content',
          value: 'id'
        },
        response: {
          text: 'content',
          value: 'id'
        }
      },
      commonContentParams: {
        searchUrl: getContentCommonURL,
        request: {
          text: 'content',
          value: 'id'
        },
        response: {
          text: 'content',
          value: 'id'
        }
      },
      areaOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      extraParams: {},
      searchParams: {
        name: '',
        serialNum: '',
        communityId: '',
        deviceStatus: undefined,
        thirdLevel: '',
        firstLevel: '',
        secondLevel: '',
        address: '',
        area: undefined,
        regionId: '',
        startDate: this.calculateDate(false, 1),
        endDate: this.calculateDate(true, 0),
        userName: '',
        deviceRoomId: '',
        maintCompanyId: '',
        commonContent: '',
        brand: '',
        isPatrol: undefined,
        isMaint: undefined,
        workType: undefined
      },
      headers: [
        {
          prop: 'name',
          label: '设备名称'
        },
        {
          prop: 'serialNum',
          label: '设备编号'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'address',
          label: '安装位置'
        },
        {
          prop: 'firstName',
          label: '分类信息',
          formatter (row) {
            let tempArr = []
            if (row.firstName && row.firstName.length) {
              tempArr.push(row.firstName)
            }
            if (row.secondName && row.secondName.length) {
              tempArr.push(row.secondName)
            }
            if (row.thirdName && row.thirdName.length) {
              tempArr.push(row.thirdName)
            }
            return tempArr.join('--')
          }
        },
        {
          prop: 'deviceStatus',
          label: '状态'
        },
        {
          prop: 'patrolStatusStr',
          label: '是否巡检',
          formatter (row) {
            return patrolMaintenanceSearchStatusMap[row.isPatrol]
          }
        },
        {
          prop: 'maintenanceStatusStr',
          label: '是否维保',
          formatter (row) {
            return patrolMaintenanceSearchStatusMap[row.isMaint]
          }
        },
        {
          prop: 'workTypeStr',
          label: '巡检/维保方式',
          formatter (row) {
            let str = workTypeSearchMap[row.workType]
            if(row.qrCode) {
              str += row.qrCode
            }
            return str
          }
        },
        {
          prop: 'qrIntime',
          label: '首次生成二维码时间',
        },
        {
          prop:'patrolPeriod',
          label:'巡检周期', //日，周，半月，月，季度，半年
          formatter(row){
            if(row.isPatrol === 1){
              return periodFormMap[row.patrolPeriod]
            }else {
              return ''
            }
          }
        },
        {
          prop:'maintPeriod',
          label:'维保周期',//周，半月，月，季度，半年，年
          formatter(row){
            //维保状态为未设置和否显示空
            if(row.isMaint === 1){
              return periodOpsMap[row.maintPeriod]
            }else {
              return ''
            }
          }
        },
        {
          prop: 'intime',
          label: '登记时间'
        }
      ],
      qrCodeId: '',
      qrCodeObj: {
        name: '',
        code: '',
        address: ''
      },
      dialogQrcode: false,
      dialogQrcodeList: false,
      qrcodeList: [],
      //搜索树
      treeData:[],
      defaultProps: {
        children: 'children',
        label: 'name',
        id:'id',
      },
      treeLoading:false,
      highlightCurrent:true,
      changeFlag: true,
      tableData: {
        resultList: []
      }
    }
  },
  computed: {
    defaultTimeType () {
      let { deviceRoomId, secondLevel, thirdLevel } = this.$route.query
      return deviceRoomId || secondLevel || thirdLevel ? undefined : 'month'
    },
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  watch: {
    searchParams: {
      handler () {
        this.changeFlag = true
      },
      deep: true
    }
  },
  created () {
    if (this.$route.query.deviceRoomId) {
      this.extraParams.deviceRoomId = this.$route.query.deviceRoomId
      this.searchParams.deviceRoomId = this.$route.query.deviceRoomId
    }
    if (this.$route.query.secondLevel) {
      this.extraParams.secondLevel = this.$route.query.secondLevel
      this.searchParams.secondLevel = this.$route.query.secondLevel
    }
    if (this.$route.query.thirdLevel) {
      this.extraParams.thirdLevel = this.$route.query.thirdLevel
      this.searchParams.thirdLevel = this.$route.query.thirdLevel
    }
  },
  mounted(){
    this.getTreeData()
    this.searchParams.firstLevel='';
    this.searchParams.secondLevel='';
    this.searchParams.thirdLevel='';
  },
  methods: {
    async batchDelete (selected) {
      let isOk = await this.$confirm('请确认是否执行此操作，删除后设备无法查看及执行任务')
      if (isOk) {
        let ids = selected.ids.join(',')
        this.$axios.post(batchDeleteDeviceURL, {
          ids,
          dataObject: ids
        }).then(res => {
          if (res.status === '100') {
            this.$refs.list.searchData()
          }
        })
      }
    },
    async batchCreateQrcode (selectedData) {
      const ids = []
      selectedData.data.forEach(item => {
        if(item.workType === 2) {
          ids.push(item.id)
        }
      })
      if(ids.length === 0) {
        this.$message({
          type: 'error',
          message: '请选择二维码设备',
          center: true
        })
        return;
      }
      if (ids.length <= 500) {
        // 进行请求的操作
        let _this = this
        this.$confirm(`已选择${ids.length}条数据,是否全部生成二维码？`, {
          title: '提示'
        }).then(confirm => {
          if (confirm) {
            // let postData = JSON.parse(JSON.stringify(_this.searchParams))
            // postData = this.handleParams(postData)
            this.$axios.post(batchGenerateQrcodeURLNew, ids).then(res => {
              if (res.status == '100') {
                let { data } = res
                this.qrcodeList = data.map(item => {
                  return {
                    ...item,
                    encryCode: item.qrCode
                  }
                })
                this.dialogQrcodeList = true
              }
            })
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: '一次最多生成500条数据，请重新选择',
          center: true
        })
      }
    },
    searchSuccess(res) {
      this.tableData = res.data
      this.changeFlag = false
    },
    handleParams(params) {
      let paramsNew = {}
      for (const paramsKey in params) {
        const element = params[paramsKey]
        if(element || element === 0) {
          paramsNew[paramsKey] = element
        }
      }
      return paramsNew
    },
    //批量生成巡检日志
    async batchCreatePatrolRecord(selected){
      let existZero = false;
      for(let i =0;i<selected.data.length;i++){
        if(selected.data[i].isPatrol !== 1){
          existZero = true;
          break;
        }
      }
      if(existZero){
        this.$confirm('存在不能生成巡检日志的记录')
        return
      }
      let isOk = await this.$confirm('确定执行该操作？')
      if(isOk){
        this.$axios({
          url:generateDevicePatrolBatchURL,
          method:'POST',
          data:selected.ids,
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          this.$refs.list.searchData()
          if (res.status === 100) {
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    //批量生成维保计划
    async batchCreateMaintRecord(selected){
      let existZero = false;
      for(let i =0;i<selected.data.length;i++){
        if(selected.data[i].isMaint !== 1){
          existZero = true;
          break;
        }
      }
      if(existZero){
        this.$confirm('存在不能生成维保计划的记录')
        return
      }
      let isOk = await this.$confirm('确定执行该操作？')
      if(isOk){
        console.log(selected)
        this.$axios({
          url:generateMaintLogURL,
          data:selected.ids,
          method:'post',
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          this.$refs.list.searchData()
          if (res.status === 100) {
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    goPage (name) {
      this.$router.push({
        name
      })
    },
    edit (row) {
      this.$router.push({
        name: 'deviceConfigForm',
        query: {
          id: row.id
        }
      })
    },
    moreHandle (val) {
      if (val.type === 1) {
        this.createQrcode(val.row)
      } else if (val.type === 2) {
        this.creatRecord(val.row)
      } else if (val.type === 3) {
        this.createMaintRecord(val.row)
      }
    },
    createQrcode (row) {
      let { id, name, address } = row
      this.$axios.post(getGenerateQrcodeURL, {
        id,
        dataObject: name
      }).then(res => {
        if (res.status === '100') {
          let { data } = res
          this.qrCodeId = `img_${data}`
          this.qrCodeObj = {
            code: data,
            name,
            address
          }
          this.dialogQrcode = true
          this.$nextTick(() => {
            new QRCode(this.qrCodeId, { // eslint-disable-line
              width: 200,
              height: 200,
              text: data
            })
          }, 20)
        }
      })
    },
    //生成巡检日志
    async creatRecord (row) {
      let isOk = await this.$confirm('确定执行该操作？')
      let { id, name } = row
      isOk && this.$axios.get(generateDevicePatrolSingleURL, {
        params: {
          id,
          dataObject: name
        }
      }).then(res => {
        this.$refs.list.searchData()
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    //生成维保计划
    async createMaintRecord(row){
      let isOk = await this.$confirm('确定执行该操作？')
      let { id} = row
      let deviceIds=[]
      deviceIds.push(id)
      console.log(isOk)
      isOk && this.$axios({
        url:generateMaintLogURL,
        method:'post',
        data:deviceIds,
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        this.$refs.list.searchData()
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    // 保存二维码到本地
    downloadQRCode () {
      // downloadHelper
      let qrcodeEle = document.getElementById(this.qrCodeId)
      let url = qrcodeEle.children[1].src
      downloadHelper.downloadBySrc({
        src: url,
        fileName: this.qrCodeObj.name
      })
      this.dialogQrcode = false
    },
    closeDialog () {
      this.qrCodeId = ''
    },
    communityChange (val) {
      if (val && val.id) {
        this.$axios.get(select2BlockURL, { params: { communityId: val.id } }).then(res => {
          if (res.status === 100) {
            let ops = res.data.map(item => {
              return {
                text: item.name,
                value: item.name
              }
            })
            this.areaOps = [{ text: '全部', value: undefined }, ...ops]
            this.searchParams.area = undefined
          }
        })
      }
    },
    async createDb () {
      let isOk = await this.$confirm('确认执行此操作？')
      isOk && this.$axios.get(generateOneCommunityDeviceDBURL).then(res => {
        if (res.status === '100') {
          this.$message.success('操作成功！')
        }
      })
    },
    // 计算时间,type类型，days天数
    calculateDate(add, days){
      // true加 false减
      if (add) {
        return moment().add(days, 'd').format('YYYY-MM-DD')
      } else {
        return moment().subtract(days, 'months').format('YYYY-MM-DD')
      }
    },
    /**
     * data:节点数据对象
     */
    getLevel(number){
      let level = '';
      switch(number){
        case 1:
          level = 'firstLevel'
          break;
        case 2:
          level = 'secondLevel'
          break;
        case 3:
          level = 'thirdLevel'
          break;
        default:
          break;
      }
      return level
    },
    nodeClick(data,Node){
      console.log('11')
      let id = data['id']
      // 点击之前清空之前的数据
      this.searchParams.firstLevel = undefined;
      this.searchParams.secondLevel = undefined;
      this.searchParams.thirdLevel = undefined;
      this.searchParams[this.getLevel(Node.level)]=id
      this.highlightCurrent = true
      this.$refs.list.searchPanelSearchData()
      //请求之后参数也要清空
      // this.searchParams[this.getLevel(Node.level)]=undefined
    },
    /**
     * 获取treeData数据
     */
    async getTreeData(){
      this.treeLoading=true;
      let res = await  this.$axios.get(getTreeDataURL);
      this.treeLoading = false;
      if(res.status = 100) {
        this.treeData = res.data;
      }
    },
    cancleTree(){
      this.$refs.tree.setCheckedKeys([]);
      this.highlightCurrent = false
      this.searchParams.firstLevel = undefined;
      this.searchParams.secondLevel = undefined;
      this.searchParams.thirdLevel = undefined;
      this.$refs.list.searchPanelSearchData()
    },
  }
}
</script>
<style scope lang="scss">
.deviceConfigList-wrapper {
  .qrcode-wrapper {
    display: block;
    .title-text {
      margin-top: -10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 24px;
      border-bottom: 1px dotted #333;
    }
    .qrcode-value {
      padding: 10px;
      text-align: center;
    }
    .qrcode-img-wrapper {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 10px 0;
    }
  }
  .tree-group {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100%;
    text-align: left;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 47px;
      color: rgb(144, 147, 153);
      padding: 0 10px;
      border-top: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5;
    }
    &-content {
      flex: 1;
      overflow: auto;
      padding: 8px;
      border-bottom: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5;
      ::v-deep .custom-tree-node {
        .icon {
          display: none;
        }
      }
    }
  }
}
</style>
